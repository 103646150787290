import {type WhitelabelAppId} from 'web-app/shared/constants';
import ENV from 'web-app/config/environment';

// defined in app/config/environment.d.ts
export interface EmberEnvironment {
    environment: any;
    modulePrefix: string;
    podModulePrefix: string;
    locationType: string;
    rootURL: string;
    releaseUrl: string;

    isDevelopment: boolean;
    NODE_ENV: string;
    analytics: {
        url: string;
    };
    APP: {
        version: string;
        commit: string;
        platform: string;
        store: string | undefined;
        buildNumber?: string;
        appKind: string;
        whitelabelAppId?: WhitelabelAppId;
        customUrlScheme?: string;
    };
    killswitchUrl: string | null;
}

type EnvironmentFunction = (env: any) => {[x: string]: any};
type Environment = EnvironmentFunction | EmberEnvironment;
const TypedENV: EnvironmentFunction | EmberEnvironment = ENV;

// `process` exists only in the webpack projects (Sign-In), added by webpack.EnvironmentPlugin
// `ENV` is an object if used with the main app (created by Ember)
const EnvironmentIsFunction = (env: Environment): env is EnvironmentFunction => typeof env === 'function';

const CURRENT_PLATFORM: string = EnvironmentIsFunction(TypedENV)
    ? (process.env.FAMLY_PLATFORM as string)
    : (TypedENV as unknown as EmberEnvironment).APP.platform;

const CURRENT_STORE: string | undefined = EnvironmentIsFunction(TypedENV)
    ? process.env.STORE
    : (TypedENV as unknown as EmberEnvironment).APP.store;

const WHITELABEL_APP_ID: string | undefined = EnvironmentIsFunction(TypedENV)
    ? process.env.WHITELABEL_APP_ID
    : (TypedENV as unknown as EmberEnvironment).APP.whitelabelAppId;

const IS_DEVELOPMENT = EnvironmentIsFunction(TypedENV)
    ? process.env.NODE_ENV !== 'production'
    : (TypedENV as unknown as EmberEnvironment).isDevelopment;

const APP = EnvironmentIsFunction(TypedENV)
    ? {
          version: '',
          buildNumber: '',
      }
    : (TypedENV as unknown as EmberEnvironment).APP;

export default {
    CURRENT_PLATFORM,
    CURRENT_STORE,
    IS_DEVELOPMENT,
    APP,
    WHITELABEL_APP_ID,
};
