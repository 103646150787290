import {decrypt, encrypt, getEncryptionKey} from 'web-app/util/famlytics/storage';
import {hasValue} from '@famly/stat_ts-utils_has-value';

let hasLocalStorageSupport;
try {
    hasLocalStorageSupport = 'localStorage' in window && window.localStorage !== null;
    const testKey = 'pascalprecht.translate.storageTest';
    window.localStorage.setItem(testKey, 'foo');
    window.localStorage.removeItem(testKey);
} catch (e) {
    hasLocalStorageSupport = false;
}

let loginId;
let userContextId;
let secret;

export const setLoginIdForLocalStorage = id => {
    loginId = id;
};

export const setUserContextIdForLocalStorage = id => {
    userContextId = id;
};

export const clearLoginIdForLocalStorage = () => {
    loginId = undefined;
};

export const clearUserContextIdForLocalStorage = () => {
    userContextId = undefined;
};

export const setSecretForLocalStorage = key => {
    secret = key;
};

export const clearSecretForLocalStorage = () => {
    secret = undefined;
};

const LocalStorage = {
    buildUserKey(loginId, key) {
        return `${loginId}.${key}`;
    },

    buildUserContextKey(loginId, contextId, key) {
        return `${loginId}.${contextId}.${key}`;
    },

    // --- General Local Storage Functions ---

    getAllKeys() {
        if (!hasLocalStorageSupport) {
            return [];
        }

        return Object.keys(window.localStorage);
    },

    saveToLocalStorage(key, data, prefix = 'famly') {
        if (data !== null && data !== undefined && hasLocalStorageSupport) {
            try {
                window.localStorage.setItem(`${prefix}.${key}`, JSON.stringify(data));
            } catch (exception) {}
        }
    },

    getFromLocalStorage(key, prefix = 'famly') {
        if (!hasLocalStorageSupport) {
            return null;
        }

        return JSON.parse(window.localStorage.getItem(`${prefix}.${key}`));
    },

    clearKey(key, prefix = 'famly') {
        try {
            window.localStorage.removeItem(`${prefix}.${key}`);
        } catch (exception) {}
    },

    // --- Save Encrypted Functions ---

    saveForUserEncrypted(key, payload, withKeyPart) {
        if (loginId && secret) {
            this.saveToLocalStorage(
                this.buildUserKey(loginId, key),
                encrypt(payload, getEncryptionKey(secret, loginId, withKeyPart)),
            );
        }
    },

    saveForUserContextEncrypted(key, payload, withKeyPart) {
        if (loginId && userContextId && secret) {
            this.saveToLocalStorage(
                this.buildUserContextKey(loginId, userContextId, key),
                encrypt(payload, getEncryptionKey(secret, loginId, withKeyPart)),
            );
        }
    },

    // --- Save Unencrypted Functions ---

    saveForUserUnencrypted(key, payload) {
        if (loginId) {
            this.saveToLocalStorage(this.buildUserKey(loginId, key), payload);
        }
    },

    saveForUserContextUnencrypted(key, payload) {
        if (loginId && userContextId) {
            this.saveToLocalStorage(this.buildUserContextKey(loginId, userContextId, key), payload);
        }
    },

    // --- Get Unencrypted Functions ---

    getForUserUnencrypted(key) {
        if (loginId) {
            return this.getFromLocalStorage(this.buildUserKey(loginId, key));
        }

        return undefined;
    },

    getForUserContextUnencrypted(key) {
        if (loginId && userContextId) {
            return this.getFromLocalStorage(this.buildUserContextKey(loginId, userContextId, key));
        }

        return undefined;
    },

    // --- Exists Functions ---

    existsForUser(key) {
        if (!loginId || !secret) {
            return false;
        }
        return hasValue(this.getFromLocalStorage(this.buildUserKey(loginId, key)));
    },

    existsForUserContext(key) {
        if (!loginId || !secret || !userContextId) {
            return false;
        }
        return hasValue(this.getFromLocalStorage(this.buildUserContextKey(loginId, userContextId, key)));
    },

    // --- Get Encrypted Functions ---

    getForUserEncrypted(key, withKeyPart) {
        if (loginId && secret) {
            const storedInfo = this.getFromLocalStorage(this.buildUserKey(loginId, key));
            if (storedInfo) {
                return decrypt(storedInfo, getEncryptionKey(secret, loginId, withKeyPart));
            }
        }
        return undefined;
    },

    getForUserContextEncrypted(key, withKeyPart) {
        if (loginId && userContextId && secret) {
            const storedInfo = this.getFromLocalStorage(this.buildUserContextKey(loginId, userContextId, key));
            if (storedInfo) {
                return decrypt(storedInfo, getEncryptionKey(secret, loginId, withKeyPart));
            }
        }
        return undefined;
    },

    // --- Clear Functions ---

    clearForUser(key) {
        if (loginId) {
            this.clearKey(this.buildUserKey(loginId, key));
        }
    },

    clearForUserContext(key) {
        if (loginId && userContextId) {
            this.clearKey(this.buildUserContextKey(loginId, userContextId, key));
        }
    },

    // --- Deprecated Functions ---

    /** @deprecated use getForUserEncrypted or getForUserUnencrypted */
    getForUser(key, withKeyPart) {
        return this.getForUserEncrypted(key, withKeyPart);
    },

    /** @deprecated use saveForUserEncrypted or saveForUserUnencrypted */
    saveForUser(key, payload, withKeyPart) {
        this.saveForUserEncrypted(key, payload, withKeyPart);
    },
};

export default LocalStorage;
