import React from 'react';
import {useHistory} from 'react-router-dom';

import {type NonEmptyArray} from 'web-app/util/non-empty-array';
import {hasValue} from '@famly/stat_ts-utils_has-value';

export type QueryParams = {[paramKey: string]: string};

type Config = {replace?: boolean; queryParamsToRemove?: NonEmptyArray<string>; strategy?: 'replace' | 'push'};

export const buildQueryParams = (queryParams: QueryParams, currentQueryParams?: string, config?: Config) => {
    let params: URLSearchParams;
    if (config?.replace) {
        params = new URLSearchParams(queryParams);
    } else {
        params = new URLSearchParams(currentQueryParams);
        for (const key of Object.keys(queryParams)) {
            params.set(key, queryParams[key]);
        }
    }

    if (hasValue(config) && hasValue(config.queryParamsToRemove)) {
        for (const paramKey of config.queryParamsToRemove) {
            params.delete(paramKey);
        }
    }

    return params;
};

/**
 * A hook for updating query parameters
 * inside react-router.
 *
 * Usage
 * ```
 * const updateQueryParams = useUpdateQueryParams();
 * const queryParams = {date: '2020-05-05'}
 * updateQueryParams(queryParams);
 * ```
 *
 * @deprecated use the improved `useStatQueryParams` instead for better performance and easier usage
 * @see {@link file://./../staffing-attendance/utils/use-stat-query-param.ts}
 */

export const useUpdateQueryParams = () => {
    const history = useHistory();

    const updateQueryParams = React.useCallback(
        (queryParams: QueryParams, config?: Config) => {
            if (!history) {
                console.warn(
                    "useUpdateQueryParams seems to be used in a non-ReactRouter context. This is not possible due to its usage of ReactRouter's `useHistory` hook",
                );
                return;
            }

            const params = buildQueryParams(queryParams, history.location.search, config);
            const path = `${history.location.pathname}?${params.toString()}`;

            // The default strategy is to replace the current history when updating query parameters
            const strategy = config?.strategy ?? 'replace';
            if (strategy === 'replace') {
                history.replace(path);
            } else {
                history.push(path);
            }
        },
        [history],
    );

    return updateQueryParams;
};
